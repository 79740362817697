<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Role"
                   :edit="edit"
                   :save="save"
                   :cancel="cancel">
        <h1 class="title"
            data-test="sel-role-header">
          <breadcrumb location="/roles"
                      :text="$t('entities.role')"></breadcrumb>
          {{ role.name }}
        </h1>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <div class="tabs">
            <ul>
              <li :class="{'is-active': currentTab == 0}"
                  @click="currentTab = 0"
                  cy-data="role-information-tab">
                <a>{{$t('common.information')}}</a>
              </li>
              <li :class="{'is-active': currentTab == 1}"
                  @click="currentTab = 1"
                  cy-data="role-menu-tab">
                <a>{{$t('common.menu')}}</a>
              </li>
              <li :class="{'is-active': currentTab == 2}"
                  @click="currentTab = 2"
                  cy-data="role-object-tab">
                <a>{{$t('common.objects')}}</a>
              </li>
              <li :class="{'is-active': currentTab == 3}"
                  @click="currentTab = 3"
                  cy-data="role-workflow-tab">
                <a>{{$t('common.workflow')}}</a>
              </li>
              <li :class="{'is-active': currentTab == 4}"
                  @click="currentTab = 4"
                  cy-data="role-option-tab">
                <a>{{$t('common.options')}}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="panel-block is-block no-border"
             v-if="currentTab == 0">
          <text-field :label="$t('common.name')"
                      v-model="role.name"
                      required></text-field>
          <text-field :label="$t('common.description')"
                      v-model="role.description"></text-field>
          <selector-field :label="$t('common.visibility')"
                          v-model="role.visibility"
                          :options="$api.fetchVisibilityTypes"></selector-field>
        </div>

        <div class="panel-block is-block no-border"
             v-if="currentTab == 1">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th class="has-text-left">{{$t('common.menu')}}</th>
                <th>{{$t('common.display')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="editMode">
                <td></td>
                <td>
                  <select-all v-model="role.menus"
                              :entities="menus"
                              pKey="id" />
                </td>
              </tr>
              <tr v-for="(menu, index) in menus"
                  :key="index">
                <td class="has-text-left">{{ menu.parent ? menu.parent.title + ' / ' : '' }}{{ menu.title }}</td>
                <td>
                  <checkbox-field :value="menu.id"
                                  v-model="role.menus"
                                  :inline="false" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="panel-block is-block no-border objects"
             v-if="currentTab == 2">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th class="has-text-left">{{$t('common.object')}}</th>
                <th>{{$t('common.creation')}}</th>
                <th>{{$t('common.display')}}</th>
                <th>{{$t('common.edition')}}</th>
                <th>{{$t('common.deletion')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="editMode">
                <td></td>
                <td>
                  <select-all v-model="role.create"
                              :entities="entities"
                              pKey="code" />
                </td>
                <td>
                  <select-all v-model="role.read"
                              :entities="entities"
                              pKey="code"></select-all>
                </td>
                <td>
                  <select-all v-model="role.update"
                              :entities="entities"
                              pKey="code"></select-all>
                </td>
                <td>
                  <select-all v-model="role.delete"
                              :entities="entities"
                              pKey="code"></select-all>
                </td>
              </tr>
              <tr v-for="(entity, index) in entities"
                  :key="index">
                <td class="has-text-left">{{ entity.name }}</td>
                <td>
                  <checkbox-field :value="entity.code"
                                  v-model="role.create"
                                  :inline="false" />
                </td>
                <td>
                  <checkbox-field :value="entity.code"
                                  v-model="role.read"
                                  :inline="false" />
                </td>
                <td>
                  <checkbox-field :value="entity.code"
                                  v-model="role.update"
                                  :inline="false" />
                </td>
                <td>
                  <checkbox-field :value="entity.code"
                                  v-model="role.delete"
                                  :inline="false" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="panel-block is-block no-border"
             v-if="currentTab == 3">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th class="has-text-left">{{$t('entities.workflowNode')}}</th>
                <th class="has-text-centered">{{$t('common.display')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="editMode">
                <td></td>
                <td>
                  <select-all v-model="role.nodes"
                              :entities="nodes"
                              pKey="id" />
                </td>
              </tr>
              <tr v-for="(node, index) in nodes"
                  :key="index">
                <td class="has-text-left">
                  <b>{{ node.workflow.name }}</b>
                  {{ node.name }}
                </td>
                <td>
                  <checkbox-field :value="node.id"
                                  v-model="role.nodes"
                                  :inline="false"></checkbox-field>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th class="has-text-left">{{$t('common.transitions')}}</th>
                <th class="has-text-centered">{{$t('common.display')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="editMode">
                <td></td>
                <td>
                  <select-all v-model="role.transitions"
                              :entities="transitions"
                              pKey="id" />
                </td>
              </tr>
              <tr v-for="(transition, index) in transitions"
                  :key="index">
                <td class="has-text-left">
                  <b>{{ transition.fromNode.name }} -> {{ transition.toNode.name }}</b>
                  {{ transition.transitionCode }}
                </td>
                <td>
                  <checkbox-field :value="transition.id"
                                  v-model="role.transitions"
                                  :inline="false"></checkbox-field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="panel-block is-block no-border"
             v-if="currentTab == 4">
          <article class="message is-dark">
            <div class="message-body">
              {{$t('roles.additionalPermissions')}}
            </div>
          </article>

          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th class="has-text-left">{{$t('common.permissions')}}</th>
                <th>{{$t('common.activeF')}}</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="category in options">
                <tr :key="category.code">
                  <td class="has-text-left"><b>{{ category.name }}</b></td>
                  <td></td>
                </tr>
                <tr v-for="option in category.options"
                    :key="option.code">
                  <td class="has-text-left">{{ option.name }}</td>
                  <td>
                    <checkbox-field :cy-data="`${option.code}`"
                                    :value="category.code + '.' + option.code"
                                    v-model="role.options"
                                    :inline="false"></checkbox-field>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'roles',
  props: ['id'],
  computed: {
    ...mapGetters({
      editMode: 'states/isEdition',
    }),
  },
  data () {
    return {
      currentTab: 0,
      role: {},
      menus: [],
      entities: [],
      nodes: [],
      transitions: [],
      options: [],
    };
  },
  mounted () {
    this.fetchData();
  },
  methods: {
    fetchData () {
      axios.get('/menus').then((response) => {
        this.menus = response.data;
      });

      axios.get('/workflows/nodes').then((response) => {
        this.nodes = response.data;
      });

      axios.get('/workflows/transitions').then((response) => {
        this.transitions = response.data;
      });

      axios.get('/role/options').then((response) => {
        this.options = response.data;
      });

      axios
        .get('/entities')
        .then((response) => {
          this.entities = response.data;
        })
        .then(() => {
          axios.get(`/role/${this.id}`).then((response) => {
            this.$store.dispatch('states/setEdition', false);
            this.role = response.data;
            const visibilityTypes = this.$api.fetchVisibilityTypes();
            this.role.visibility = visibilityTypes[this.role.visibility - 1];
          });
        });
    },
    save () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const visibilityTypes = this.$api.fetchVisibilityTypes();
          const visibilityCode = visibilityTypes.findIndex(
            type => type === this.role.visibility,
          );
          const role = {
            ...this.role,
          };
          role.visibility = visibilityCode + 1;
          return axios.put(`/role/${this.id}`, role).then((response) => {
            if (response.data.success) {
              this.fetchData();
            }
          });
        }
        return Promise.resolve();
      });
    },
    edit () {
      this.$store.dispatch('states/setEdition', true);
    },
    cancel () {
      this.fetchData();
    },
  },
};
</script>

<style lang='scss' scoped>
.panel-heading {
  padding: 0em 0.75em;
  .tabs {
    ul {
      border-bottom: none;
    }
    li {
      width: 25%;
      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }
      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}

.table {
  th,
  td {
    text-align: center;
  }
}

.no-border {
  border: none;
  div {
    width: 100%;
    span {
      width: 30%;
      display: inline-block;
    }
  }
}
</style>
