<template>
  <div class="select" :class="{'is-loading': loading}">
    <select @change="onChange" v-model="val">
      <option v-if="showEmpty" :value="null" disabled>- select an option -</option>
      <option
        v-for="(option, index) in optionsArray"
        :key="index"
        :value="typeof option === 'object' ? option : index"
      >
        <slot :option="option">{{ option }}</slot>
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'select-field-mod',
  props: {
    value: {
      // eslint-disable-next-line
      type: Object | String,
      default: null,
    },
    options: {
      type: Function,
    },
    showEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      val: this.value,
      optionsArray: [],
      loading: false,
    };
  },
  mounted() {
    this.refresh();
  },
  watch: {
    value(val) {
      this.val = val;
    },
  },
  methods: {
    refresh() {
      this.loading = true;
      this.options((data) => {
        this.loading = false;
        this.optionsArray = data;
      });
    },
    onChange() {
      this.$emit('input', this.val);
    },
  },
};
</script>

<style lang='scss' scoped>
</style>
