<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$entitiesName.SubcontractorMarket"
        :create="openCreateModal"
      >
        <h1 class="title">{{ $t("entities.contractorAgreements") }}</h1>
      </page-header>

      <datatable
        :fetch="$api.fetchMarkets"
        ref="marketList"
        :enableDownload="true"
        objectName="markets"
      >
        <div
          slot="edifice"
          slot-scope="{ item }"
          :title="$t('markets.columns.edifice')"
          :sortable="true"
        >
          {{ item.edifice }}
        </div>
        <div
          slot="contractNumber"
          slot-scope="{ item }"
          :title="$t('markets.columns.contractNumber')"
          :sortable="true"
        >
          <restricted-link
            v-if="contract"
            entity="contract"
            :to="`/sst/contract/${contract.id}`"
          >
            {{ item.contractNumber }}
          </restricted-link>
        </div>
        <div
          slot="dateEndOfContract"
          slot-scope="{ item }"
          :title="$t('markets.columns.dateEndOfContract')"
          :sortable="true"
        >
          {{ item.dateEndOfContract }}
        </div>
        <div
          slot="sapReference"
          slot-scope="{ item }"
          :title="$t('markets.columns.marketNumber')"
          :sortable="true"
        >
          {{ item.sapReference }}
        </div>
        <div
          slot="agency"
          slot-scope="{ item: { agency } }"
          :title="$t('common.agency')"
          :sortable="true"
          sortBy="agency.edifice"
        >
          <restricted-link
            v-if="agency"
            entity="agency"
            :to="`/sst/agency/${agency.id}`"
          >
            <agency-display :value="agency" />
          </restricted-link>
        </div>
        <div
          slot="agency.company"
          slot-scope="{ item: { agency } }"
          :title="$t('common.company')"
          :sortable="true"
          sortBy="agency.company.name"
        >
          <restricted-link
            v-if="agency"
            entity="company"
            :to="`/sst/company/${agency.company.id}`"
          >
            {{ agency.company.name }}
          </restricted-link>
        </div>
        <div
          slot="project"
          slot-scope="{ item: { project } }"
          :title="$t('common.project')"
          :sortable="true"
          sortBy="project.name"
        >
          <restricted-link
            v-if="project"
            entity="project"
            :to="`/project/${project.id}`"
          >
            {{ project.name }}
          </restricted-link>
        </div>
        <div
          slot="tva"
          slot-scope="{ item }"
          :title="$t('common.vat')"
          :sortable="true"
        >
          {{ item.tva }}
        </div>
        <div
          slot="reverseCharge"
          slot-scope="{ item }"
          :title="$t('markets.vatTypes.reverseCharge')"
          :sortable="true"
          nofilter
        >
          <field isCheckbox :isChecked="item.reverseCharge" />
        </div>
        <div
          slot="deposit_type"
          slot-scope="{ item }"
          :title="$t('common.depositType')"
        >
          {{ item.depositType }}
        </div>
        <div
          slot="payment_type"
          slot-scope="{ item }"
          :title="$t('common.payment')"
        >
          {{ item.paymentType }}
        </div>
        <div
          slot="payment_condition"
          slot-scope="{ item }"
          :title="$t('markets.columns.paymentCondition')"
        >
          {{ item.paymentCondition }}
        </div>
        <div
          slot="active"
          slot-scope="{ item }"
          :title="$t('markets.columns.activated')"
          nofilter
        >
          <field isCheckbox :isChecked="item.active" />
        </div>
        <div slot="actions" slot-scope="{ item }">
          <router-link :to="'/sst/market/' + item.id" class="button is-primary">
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>

    <modal
      ref="createMarketModal"
      @close="closeCreateMarketModal"
      @create="createMarket"
      :title="$t('markets.marketCreation')"
    >
      <div class="columns">
        <div class="column is-half">
          <text-field
            :label="$t('markets.columns.edifice')"
            v-model="market.edifice"
            :inline="false"
            :edit="true"
            required
            cy-data="edifice"
          ></text-field>
          <many2one-field
            :label="$t('markets.columns.contractNumber')"
            v-model="market.contract"
            :fetch="$api.fetchContracts"
            reference="contractNumber"
            :entityLocation="`/sst/contract/${
              market.contract ? market.contract.id : ''
            }`"
            :columns="contractColumn"
            name="contract2one"
            data-vv-name="contract2one"
            :hasFilter="true"
            :edit="true"
            cy-data="contract-number"
          ></many2one-field>
          <date-field
            cy-data="contract-deadline"
            :label="$t('markets.columns.dateEndOfContract')"
            v-model="market.dateEndOfContract"
            :inline="false"
            :edit="true"
          ></date-field>
          <many2one-field
            :label="$t('common.agency')"
            v-model="market.agency"
            :fetch="$api.fetchAgencies"
            reference="edifice"
            :entityLocation="`/sst/agency/${
              market.agency ? market.agency.id : ''
            }`"
            :columns="columnsAgency"
            :inline="false"
            :edit="true"
            name="agency2one"
            data-vv-name="agency2one"
            :hasFilter="true"
            v-validate="{ required: true }"
            required
            :error="errors.has('agency2one')"
            cy-data="agency"
          ></many2one-field>
          <many2one-field
            :label="$t('common.project')"
            v-model="market.project"
            :fetch="$api.fetchProjects"
            reference="name"
            :entityLocation="`/project/${
              market.project ? market.project.id : ''
            }`"
            :columns="columnsProject"
            :inline="false"
            :edit="true"
            :hasFilter="true"
            name="project2one"
            data-vv-name="project2one"
            v-validate="{ required: true }"
            required
            :error="errors.has('project2one')"
            cy-data="project"
          ></many2one-field>
          <selector-field
            cy-data="vat-type"
            :label="$t('markets.columns.vatType')"
            v-model="market.tva"
            :inline="false"
            :edit="true"
            :options="$api.fetchTVAType"
          />
          <checkbox-field
            cy-data="vat-reversed"
            :label="$t('markets.vatTypes.reverseCharge')"
            v-model="market.reverseCharge"
            :edit="true"
            :inline="false"
          />
        </div>

        <div class="column is-half">
          <text-field
            type="number"
            :label="$t('markets.columns.maxAmountDC4')"
            v-model="market.maxAmountDC4"
            :inline="false"
            required
            :edit="true"
            cy-data="dc4-amount"
          />
          <text-field
            :label="$t('markets.columns.eotpSt')"
            v-model="market.eotpST"
            :inline="false"
            :edit="true"
            cy-data="eotp"
          />
          <selector-field
            :label="$t('common.retention')"
            v-model="market.depositType"
            :inline="false"
            :edit="true"
            :options="$api.fetchDepositType"
            cy-data="deposit-type"
          />

          <numeric-field
            v-if="
              market.depositType ===
              $t('warranties.retentionTypes.withThreshold')
            "
            type="price"
            :label="$t('markets.columns.thresholdAmount')"
            :edit="true"
            :inline="false"
            required
            v-model="market.threshold"
            cy-data="threshold"
            unit="EUR"
          ></numeric-field>
          <numeric-field
            v-if="
              market.depositType ===
                $t('warranties.retentionTypes.withThreshold') ||
              market.depositType === $t('warranties.retentionTypes.basic')
            "
            type="percentage"
            :label="$t('markets.columns.rgRate')"
            v-model="market.rgRate"
            required
            :inline="false"
            :edit="true"
            min="0"
            step="0.01"
            unit="%"
            cy-data="rg-rate"
          />

          <selector-field
            :label="$t('markets.columns.paymentType')"
            v-model="market.paymentType"
            :inline="false"
            :edit="true"
            :options="$api.fetchPaymentType"
            data-test="sel-market-payment"
            cy-data="payment-type"
          />
          <selector-field
            :label="$t('markets.columns.paymentCondition')"
            v-model="market.paymentCondition"
            :inline="false"
            :edit="true"
            :options="$api.fetchPaymentConditionAsList"
            cy-data="payment-terms"
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import { globalLocale } from "../../i18n";
import TextField from "../../components/fields/TextField.vue";
import contract from "@/pages/contracts/Contract.vue";

export default {
  computed: {
    contract() {
      return contract;
    },
  },
  components: { TextField },
  name: "market",
  inject: ["$validator"],
  data() {
    return {
      market: {
        rgRate: 5,
      },
      columnsProject: {
        name: this.$t("common.name"),
        code: this.$t("common.code"),
        "type.name": this.$t("common.type"),
        manager: this.$t("common.manager"),
      },
      columnsBPU: {
        id: this.$t("common.id"),
        name: this.$t("common.reference"),
      },
      columnsAgency: {
        "company.name": this.$t("common.company"),
        edifice: this.$t("common.agency"),
        siret: this.$t("common.siret"),
        manager: this.$t("common.manager"),
      },
      contractColumn: {
        "agency.edifice": this.$t("common.agency"),
        contractNumber: this.$t("contract.contractNumber"),
        "documentType.name": this.$t("contract.contractType"),
        "referent.name": this.$t("contract.referent"),
        "adminReferent.name": this.$t("contract.adminReferent"),
      },
      globalLocale: globalLocale,
    };
  },
  methods: {
    openCreateModal() {
      this.$refs.createMarketModal.open();
    },
    closeCreateMarketModal() {
      this.market = {
        rgRate: 5,
      };
    },
    createMarket() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        axios.post("/market/create", this.market).then((response) => {
          if (!response.data.success) {
            return;
          }

          // this.$refs.marketList.refresh();
          // this.$refs.createMarketModal.close();
          this.$router.push({
            name: "Market",
            params: { id: response.data.id },
          });
        });
      });
    },
  },
};
</script>

<style scoped>
</style>
