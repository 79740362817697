<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Contract" :create="openCreateModal">
        <h1 class="title">{{ $t("common.contracts") }}</h1>
      </page-header>

      <datatable
        :fetch="$api.fetchContracts"
        ref="listContracts"
        :enableDownload="true"
        objectName="contracts"
      >
        <div
          slot="agency"
          slot-scope="{ item: { agency } }"
          :title="$t('common.agency')"
          :sortable="true"
        >
          <restricted-link
            v-if="agency"
            entity="company"
            :to="`/sst/agency/${agency.id}`"
          >
            {{ agency && agency.edifice }}
          </restricted-link>
        </div>
        <div
          slot="contractNumber"
          slot-scope="{ item }"
          :title="$t('contract.contractNumber')"
          :sortable="true"
        >
          {{ item.contractNumber }}
        </div>
        <div
          slot="parentContract"
          slot-scope="{ item: { parent } }"
          :title="$t('contract.parentContract')"
          :sortable="true"
        >
          <restricted-link
            v-if="parent"
            entity="company"
            :to="`/sst/contract/${parent.id}`"
          >
            {{ parent && parent.contractNumber }}
          </restricted-link>
        </div>
        <div
          slot="documentType"
          slot-scope="{ item }"
          :title="$t('contract.contractType')"
          :sortable="true"
        >
          {{ item.documentType && item.documentType.name }}
        </div>
        <div
          slot="signatureDate"
          slot-scope="{ item }"
          :title="$t('contract.signatureDate')"
          :sortable="true"
          :nofilter="true"
        >
          {{ item.signatureDate | date }}
        </div>
        <div
          slot="startDate"
          slot-scope="{ item }"
          :title="$t('contract.startDate')"
          :sortable="true"
          :nofilter="true"
        >
          {{ item.startDate | date }}
        </div>
        <div
          slot="endDate"
          slot-scope="{ item }"
          :title="$t('contract.endDate')"
          :sortable="true"
          :nofilter="true"
        >
          {{ item.endDate | date }}
        </div>
        <div
          slot="duration"
          slot-scope="{ item }"
          :title="$t('contract.duration')"
          :sortable="true"
          :nofilter="true"
        >
          {{ item.duration }}
        </div>
        <div
          slot="terminationDate"
          slot-scope="{ item }"
          :title="$t('contract.terminationDate')"
          :sortable="true"
          :nofilter="true"
        >
          {{ item.terminationDate | date }}
        </div>
        <div
          slot="active"
          slot-scope="{ item }"
          :title="$t('contract.active')"
          :sortable="true"
        >
          <field isCheckbox :isChecked="item.active" />
        </div>
        <div
          slot="referent"
          slot-scope="{ item: { referent } }"
          :title="$t('contract.referent')"
          :sortable="true"
        >
          <restricted-link
            v-if="referent"
            entity="company"
            :to="`/user/${referent.id}`"
          >
            {{ referent && referent.name }}
          </restricted-link>
        </div>
        <div
          slot="adminReferent"
          slot-scope="{ item: { adminReferent } }"
          :title="$t('contract.adminReferent')"
          :sortable="true"
        >
          <restricted-link
            v-if="adminReferent"
            entity="company"
            :to="`/user/${adminReferent.id}`"
          >
            {{ adminReferent && adminReferent.name }}
          </restricted-link>
        </div>
        <div slot="actions" slot-scope="{ item }">
          <router-link
            :to="'/sst/contract/' + item.id"
            class="button is-primary"
          >
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>

    <modal
      ref="createContractModal"
      @close="closeCreateContract"
      @create="createContract"
      :title="$t('contract.contractCreation')"
    >
      <div class="columns">
        <div class="column is-half">
          <many2one-field
            :label="$t('common.agency')"
            v-model="contract.agency"
            :fetch="$api.fetchAgencies"
            reference="edifice"
            :entityLocation="`/sst/agency/${
              contract.agency ? contract.agency.id : ''
            }`"
            entity="agency"
            :columns="columns.agency"
            :inline="false"
            :edit="true"
            required
            :hasFilter="true"
            v-validate="{ required: true }"
            name="agency2one"
            :error="errors.has('agency2one')"
          ></many2one-field>

          <text-field
            :label="$t('contract.contractNumber')"
            v-model="contract.contractNumber"
            :inline="false"
            :edit="true"
            required
            v-validate="{ required: true }"
            name="contractNumber"
            :error="errors.has('contractNumber')"
          ></text-field>

          <many2one-field
            :label="$t('contract.parentContract')"
            v-model="contract.parent"
            :fetch="$api.fetchContracts"
            reference="contractNumber"
            :entityLocation="`/sst/contract/${
              contract.parent ? contract.parent.id : ''
            }`"
            entity="contract"
            :columns="columns.parent"
            :inline="false"
            :edit="true"
            :hasFilter="true"
          ></many2one-field>

          <many2one-field
            :label="$t('contract.contractType')"
            v-model="contract.documentType"
            :fetch="$api.fetchDocumentTypes"
            reference="name"
            :entityLocation="`/documenttype/${
              contract.documentType ? contract.documentType.id : ''
            }`"
            entity="contract"
            :columns="columns.documentType"
            :inline="false"
            :edit="true"
            :hasFilter="true"
            required
            v-validate="{ required: true }"
            name="documentType2one"
            :error="errors.has('documentType2one')"
          ></many2one-field>

          <date-field
            :label="$t('contract.signatureDate')"
            v-model="contract.signatureDate"
            :inline="false"
            :edit="true"
            required
            v-validate="{ required: true }"
            name="signatureDate"
            :error="errors.has('signatureDate')"
          ></date-field>

          <date-field
            :label="$t('contract.startDate')"
            v-model="contract.startDate"
            :inline="false"
            :edit="true"
            required
            v-validate="{ required: true }"
            name="startDate"
            :error="errors.has('startDate')"
          ></date-field>
        </div>

        <div class="column is-half">
          <date-field
            :label="$t('contract.endDate')"
            v-model="contract.endDate"
            :inline="false"
            :edit="true"
            required
            v-validate="{ required: true }"
            name="endDate"
            :error="errors.has('endDate')"
          ></date-field>

          <text-field
            type="number"
            :label="$t('contract.duration')"
            v-model="contract.duration"
            :inline="false"
            :edit="true"
            required
            v-validate="{ required: true }"
            name="duration"
            :error="errors.has('duration')"
          ></text-field>

          <date-field
            :label="$t('contract.terminationDate')"
            v-model="contract.terminationDate"
            :inline="false"
            :edit="true"
            name="terminationDate"
          ></date-field>

          <many2one-field
            :label="$t('contract.referent')"
            v-model="contract.referent"
            :fetch="$api.fetchUsers"
            reference="name"
            :entityLocation="`/user/${
              contract.referent ? contract.referent.id : ''
            }`"
            :columns="columns.user"
            :hasFilter="true"
            :inline="false"
            :edit="true"
            required
            name="referent"
            v-validate="{ required: true }"
            :error="errors.has('referent')"
            data-test="sel-os-adminReferent"
          ></many2one-field>

          <many2one-field
            :label="$t('contract.adminReferent')"
            v-model="contract.adminReferent"
            :fetch="$api.fetchUsers"
            reference="name"
            :entityLocation="`/user/${
              contract.adminReferent ? contract.adminReferent.id : ''
            }`"
            :columns="columns.user"
            :hasFilter="true"
            :inline="false"
            :edit="true"
            required
            name="adminReferent"
            v-validate="{ required: true }"
            :error="errors.has('adminReferent')"
            data-test="sel-os-adminReferent"
          ></many2one-field>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import { globalLocale } from "../../i18n";

export default {
  name: "agencies",
  data() {
    return {
      contract: {},
      columns: {
        agency: {
          "company.name": this.$t("common.company"),
          edifice: this.$t("common.agency"),
          siret: this.$t("common.siret"),
          manager: this.$t("common.manager"),
        },
        documentType: {
          name: this.$t("common.name"),
          code: this.$t("common.code"),
        },
        user: {
          name: this.$t("common.name"),
          mail: "E-Mail",
          "role.name": this.$t("entities.role"),
          service: this.$t("common.service"),
        },
        parent: {
          "agency.edifice": this.$t("common.agency"),
          contractNumber: this.$t("contract.contractNumber"),
          "documentType.name": this.$t("contract.documentType"),
          "referent.name": this.$t("contract.referent"),
          "adminReferent.name": this.$t("contract.adminReferent"),
        },
      },
      globalLocale: globalLocale,
    };
  },
  mounted() {},
  methods: {
    openCreateModal() {
      this.$refs.createContractModal.open();
    },
    closeCreateContract() {
      this.contract = {};
    },
    createContract() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }

        const self = this;
        axios.post("/contract", this.contract).then((response) => {
          if (!response.data.success) {
            return;
          }

          self.contract = {};
          self.$refs.createContractModal.close();
          this.$router.push({
            name: "Contract",
            params: { id: response.data.id },
          });
        });
      });
    },
  },
};
</script>

<style scoped>
</style>
