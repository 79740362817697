<template>
  <modal
    ref="emailModal"
    @close="resetInput"
    @create="sendEmail"
    :action="$t('common.send')"
    :title="$t('expressions.reportIncidentToSupport')"
    :loading="isSending"
  >
    <div class="columns">
      <div class="column is-half">
        <text-field
          :label="$t('common.object')"
          v-model="subject"
          :inline="false"
          :edit="true"
          required
          cy-data="edifice"
          data-test="sel-market-edifice"
        />
        <many2one-field
          :label="$t('expressions.projectConcerned')"
          v-model="project"
          :fetch="$api.fetchProjects"
          reference="name"
          :entityLocation="`/project/${project ? project.id : ''}`"
          :columns="projects"
          :inline="false"
          :edit="true"
          required
          :hasFilter="true"
          name="project2one"
          data-vv-name="project2one"
          v-validate="{ required: true }"
          :error="errors.has('project2one')"
          data-test="sel-email-project"
        ></many2one-field>
        <textarea-field
          :label="$t('expressions.problemDescription')"
          v-model="problem_description"
          :inline="false"
          :edit="true"
          required
        />
        <!-- add attachment -->
        <div class="field">
          <button class="button is-success" @click.stop="addFile">
            {{ $t("expressions.selectAttachment") }}&nbsp;
            <i class="fa fa-choose"></i>
          </button>
        </div>
        <field
          readonly
          inline
          :label="$t('expressions.selectedAttachmentName')"
          >{{ selectedFileName }}</field
        >
        <input
          :style="{ display: 'none' }"
          ref="attachmentFileInput"
          type="file"
          name="attachmentFile"
          accept="*/*"
          data-test="sel-attachment-file-input"
          @change="selectFile"
        />
        <!-- add attachment -->
      </div>
    </div>
  </modal>
</template>
<script>
import axios from "axios";

export default {
  name: "email",
  data() {
    return {
      subject: "",
      project: null,
      projects: {
        name: "Nom",
        code: "Code",
        "type.name": "Type",
      },
      problem_description: "",
      attachment: "",
      isSending: false,
    };
  },
  computed: {
    selectedFileName() {
      if (this.attachment) {
        return this.attachment.name;
      }
      return "";
    },
  },
  methods: {
    open() {
      this.$refs.emailModal.open();
    },
    addFile() {
      this.$refs.attachmentFileInput.click();
    },
    selectFile() {
      this.attachment = this.$refs.attachmentFileInput.files[0];
    },
    sendEmail() {
      if (!(this.subject && this.project && this.problem_description)) {
        this.$awn.warning("Veuillez d'abord remplir le formulaire");
        return false;
      }
      const form = new FormData();
      form.set("subject", this.subject);
      form.set("project", this.project.name);
      form.set("problem_description", this.problem_description);
      if (this.attachment) {
        form.set("attachment", this.attachment, this.attachment.name);
      }
      this.isSending = true;
      axios
        .post("help/submit", form)
        .then((res) => {
          this.$awn.success(res.data.success);
          this.$refs.emailModal.close();
        })
        .finally(() => {
          this.isSending = false;
        });
      return true;
    },
    resetInput() {
      this.subject = "";
      this.project = null;
      this.problem_description = "";
      this.attachment = "";
    },
  },
};
</script>
