<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Prestation" :create="openCreateModal">
        <h1 class="title">{{ $t("entities.prestations") }}</h1>
      </page-header>

      <datatable
        :fetch="$api.fetchPrestations"
        ref="datatable"
        objectName="prestations"
        identifier="reference"
      >
        <div
          slot="bpu"
          slot-scope="{ item }"
          :title="$t('entities.bpuTemplate')"
          :sortable="true"
          sortBy="bpu.name"
        >
          {{ item.bpu ? item.bpu.name : "" }}
        </div>
        <div
          slot="reference"
          slot-scope="{ item }"
          :title="$t('common.reference')"
          :sortable="true"
        >
          {{ item.reference }}
        </div>
        <div
          slot="category"
          slot-scope="{ item }"
          :title="$t('common.category')"
          :sortable="true"
          sortBy="category.parent.name"
        >
          {{
            item.category && item.category.parent
              ? item.category.parent.name
              : ""
          }}
        </div>
        <div
          slot="subcategory"
          slot-scope="{ item }"
          :title="$t('common.subcategory')"
          :sortable="true"
          sortBy="category.name"
        >
          {{ item.category ? item.category.name : "" }}
        </div>
        <div
          slot="code"
          slot-scope="{ item }"
          :title="$t('prestations.columns.itemCode')"
          :sortable="true"
        >
          {{ item.code }}
        </div>
        <div
          slot="description"
          slot-scope="{ item }"
          :title="$t('common.designation')"
          :sortable="true"
        >
          {{ item.description }}
        </div>
        <div
          slot="unit"
          slot-scope="{ item }"
          :title="$t('common.unit')"
          :sortable="true"
          sortBy="unit.name"
        >
          {{ item.unit ? item.unit.name : "" }}
        </div>
        <div
          slot="type"
          slot-scope="{ item }"
          :title="$t('common.type')"
          :sortable="true"
          sortBy="type.name"
        >
          {{ item.type ? item.type.name : "" }}
        </div>

        <div slot="actions" slot-scope="{ item }">
          <router-link :to="'/prestation/' + item.id" class="button is-primary">
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>

    <modal
      ref="createPrestationModal"
      @create="createPrestation"
      :title="$t('prestations.prestationCreation')"
    >
      <div class="columns">
        <div class="column is-half">
          <many2one-field
            :label="$t('entities.bpuTemplate')"
            v-model="prestation.bpu"
            :fetch="$api.fetchTemplates"
            reference="name"
            :entityLocation="`/sst/bputemplate/${
              prestation.bpu ? prestation.bpu.id : ''
            }`"
            entity="bpu_template"
            :columns="bputemplate_columns"
            :inline="false"
            :edit="true"
            required
            :hasFilter="true"
            v-validate="{ required: true }"
            name="bputemplate2one"
            :error="errors.has('bputemplate2one')"
          ></many2one-field>
          <text-field
            :label="$t('common.reference')"
            v-model="prestation.reference"
            :inline="false"
            :edit="true"
            required
          />
          <text-field
            :label="$t('prestations.columns.itemCode')"
            v-model="prestation.code"
            :inline="false"
            :edit="true"
            required
          />
          <textarea-field
            :label="$t('common.designation')"
            v-model="prestation.description"
            :inline="false"
            :edit="true"
            required
          />
        </div>

        <div class="column is-half">
          <many2one-field
            :label="$t('common.category')"
            v-model="category"
            :fetch="$api.fetchParentCategories"
            reference="name"
            :columns="{ name: $t('common.name') }"
            :inline="false"
            required
            :edit="true"
            :hasFilter="true"
            v-validate="{ required: true }"
            name="category2one"
            :error="errors.has('category2one')"
          ></many2one-field>
          <many2one-field
            :label="$t('common.subcategory')"
            v-model="subCategory"
            :fetch="$api.fetchSubCategoriesPage.bind(this, category.id)"
            reference="name"
            :columns="{ name: $t('common.name') }"
            :inline="false"
            :hasFilter="true"
            :edit="true"
            required
            v-validate="{ required: false }"
            :readonly="!category.id"
            name="subcategory2one"
            :error="errors.has('subcategory2one')"
          >
            <span slot="helper" v-if="!category.id">{{
              $t("prestations.needCategory")
            }}</span>
          </many2one-field>

          <many2one-field
            :label="$t('common.unit')"
            v-model="prestation.unit"
            :fetch="$api.fetchUnits"
            reference="name"
            :entityLocation="`/unit/${
              prestation.unit ? prestation.unit.id : ''
            }`"
            entity="unit"
            :columns="unit_columns"
            :inline="false"
            :edit="true"
            required
            :hasFilter="true"
            v-validate="{ required: true }"
            name="unit2one"
            :error="errors.has('unit2one')"
          ></many2one-field>

          <many2one-field
            :label="$t('common.type')"
            v-model="prestation.type"
            :fetch="$api.fetchTypes"
            reference="name"
            :entityLocation="`/prestationtype/${
              prestation.type ? prestation.type.id : ''
            }`"
            entity="prestation_type"
            :columns="prestationtype_columns"
            :inline="false"
            :edit="true"
            required
            :hasFilter="true"
            v-validate="{ required: true }"
            name="prestationtype2one"
            :error="errors.has('prestationtype2one')"
          ></many2one-field>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "prestations-list",
  data() {
    return {
      prestation: {},
      bputemplate_columns: {
        name: this.$t("common.name"),
      },
      category_columns: {
        "parent.name": "Parent",
        name: this.$t("common.name"),
      },
      unit_columns: {
        name: this.$t("common.name"),
      },
      prestationtype_columns: {
        name: this.$t("common.name"),
      },
    };
  },
  computed: {
    category: {
      get() {
        if (!this.prestation.category) return {};
        return this.prestation.category.parent
          ? this.prestation.category.parent
          : this.prestation.category;
      },
      set(val) {
        if (this.prestation) {
          this.$set(this.prestation, "category", val);
        }
      },
    },
    subCategory: {
      get() {
        if (!this.prestation.category) {
          return {};
        }
        return this.prestation.category.parent
          ? this.prestation.category
          : null;
      },
      set(val) {
        if (this.prestation.category) {
          this.prestation.category = val;
          this.prestation.category.parent = this.category;
        }
      },
    },
  },
  methods: {
    openCreateModal() {
      this.$refs.createPrestationModal.open();
    },
    createPrestation() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          axios.post("/prestation", this.prestation).then((response) => {
            if (response.data.success) {
              this.$refs.datatable.refresh();
              this.$refs.createPrestationModal.close();
              this.$router.push({
                name: "Prestation",
                params: { id: response.data.id },
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
