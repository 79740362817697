<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Agency" :create="openCreateModal">
        <h1 class="title">{{ $t("common.agencies") }}</h1>
      </page-header>

      <datatable
        :fetch="$api.fetchAgencies"
        ref="listAgencies"
        :enableDownload="true"
        objectName="agencies"
      >
        <div
          slot="edifice"
          slot-scope="{ item }"
          :title="$t('common.agency')"
          :sortable="true"
        >
          <agency-display :value="item" />
        </div>
        <div
          slot="siret"
          slot-scope="{ item }"
          :title="$t('common.siret')"
          :sortable="true"
        >
          {{ item.siret }}
        </div>
        <div
          slot="manager"
          slot-scope="{ item }"
          :title="$t('common.manager')"
          :sortable="true"
        >
          {{ item.manager }}
        </div>
        <div
          slot="phone"
          slot-scope="{ item }"
          :title="$t('common.phone')"
          :sortable="true"
        >
          {{ item.phone }}
        </div>
        <div
          slot="mail"
          slot-scope="{ item }"
          :title="$t('common.email')"
          :sortable="true"
        >
          {{ item.mail }}
        </div>
        <div
          slot="company"
          slot-scope="{ item: { company } }"
          :title="$t('common.company')"
          :sortable="true"
          sortBy="company.name"
        >
          <restricted-link
            v-if="company"
            entity="company"
            :to="`/sst/company/${company.id}`"
          >
            {{ company.name }}
          </restricted-link>
        </div>
        <div slot="actions" slot-scope="{ item }">
          <router-link :to="'/sst/agency/' + item.id" class="button is-primary">
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>

    <modal
      ref="createAgencyModal"
      @close="closeCreateAgency"
      @create="createAgency"
      :title="$t('agencies.agencyCreation')"
    >
      <div class="columns">
        <div class="column is-half">
          <siret-field
            :label="$t('common.siret')"
            v-model="siret"
            :inline="false"
            :edit="true"
          ></siret-field>

          <many2one-field
            :label="$t('common.company')"
            v-model="agency.company"
            :fetch="$api.fetchCompanies"
            reference="name"
            :entityLocation="`/sst/company/${
              agency.company ? agency.company.id : ''
            }`"
            entity="company"
            :columns="columns"
            :inline="false"
            :edit="true"
            required
            :hasFilter="true"
            v-validate="{ required: true }"
            name="company2one"
            :error="errors.has('company2one')"
          ></many2one-field>
          <text-field
            :label="$t('common.agency')"
            v-model="agency.edifice"
            :inline="false"
            :edit="!siret || siret.inThirdParty === false"
            required
          ></text-field>

          <textarea-field
            :label="$t('common.address')"
            v-model="agency.address"
            :inline="false"
            :edit="!siret || siret.inThirdParty === false"
          ></textarea-field>

          <checkbox-field
            v-if="siret && siret.inThirdParty !== false"
            label="Suivi e-attestation"
            v-model="agency.synchronize"
            :inline="false"
            :edit="true"
          ></checkbox-field>

          <text-field
            v-if="
              siret &&
              siret.inThirdParty !== false &&
              agency.synchronize &&
              !siret.onboarded
            "
            type="email"
            :label="$t('agencies.columns.inviteMail')"
            v-model="agency.inviteMail"
            :inline="false"
            :edit="true"
          ></text-field>
          <article
            v-if="
              siret &&
              siret.inThirdParty !== null &&
              agency.synchronize &&
              !siret.onboarded
            "
            slot="helper"
            class="message is-info"
          >
            <div class="message-body">{{ $t("agencies.inviteMailHint") }}</div>
          </article>
        </div>

        <div class="column is-half">
          <text-field
            :label="$t('common.manager')"
            v-model="agency.manager"
            :inline="false"
            :edit="true"
          ></text-field>
          <text-field
            :label="$t('common.phone')"
            v-model="agency.phone"
            :inline="false"
            :edit="true"
          ></text-field>
          <text-field
            :label="$t('common.email')"
            v-model="agency.mail"
            type="email"
            :inline="false"
            :edit="true"
          ></text-field>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import { globalLocale } from "../../i18n";

export default {
  name: "agencies",
  data() {
    return {
      agency: {},
      siret: null,
      columns: {
        name: this.$t("common.name"),
        siren: this.$t("common.siren"),
        manager: this.$t("common.manager"),
        edifice: this.$t("common.agency"),
      },
      globalLocale: globalLocale,
    };
  },
  mounted() {},
  watch: {
    siret(val) {
      if (!val) {
        this.agency = {
          inThirdParty: false,
          edifice: "",
          siret: "",
          address: "",
          synchronize: false,
          inviteMail: "",
        };
        return;
      }

      if (!val.inThirdParty) {
        this.agency = {
          siret: val.siret,
          inThirdParty: false,
        };
        return;
      }

      this.agency = {
        inThirdParty: val.inThirdParty,
        edifice: val.name,
        siret: val.siret,
        address: val.address,
        synchronize: true,
        inviteMail: "",
      };
    },
  },
  methods: {
    openCreateModal() {
      this.$refs.createAgencyModal.open();
    },
    closeCreateAgency() {
      this.agency = {};
    },
    createAgency() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }

        const self = this;
        axios.post("/agency/create", this.agency).then((response) => {
          if (!response.data.success) {
            return;
          }

          self.agency = {};
          // self.$refs.listAgencies.refresh();
          self.$refs.createAgencyModal.close();
          this.$router.push({
            name: "Agency",
            params: { id: response.data.id },
          });
        });
      });
    },
  },
};
</script>

<style scoped>
</style>
