var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"container is-fluid"},[_c('page-header',{attrs:{"entity":_vm.$entitiesName.Agency,"create":_vm.openCreateModal}},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("common.agencies")))])]),_c('datatable',{ref:"listAgencies",attrs:{"fetch":_vm.$api.fetchAgencies,"enableDownload":true,"objectName":"agencies"},scopedSlots:_vm._u([{key:"edifice",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.agency'),"sortable":true}},[_c('agency-display',{attrs:{"value":item}})],1)}},{key:"siret",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.siret'),"sortable":true}},[_vm._v(" "+_vm._s(item.siret)+" ")])}},{key:"manager",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.manager'),"sortable":true}},[_vm._v(" "+_vm._s(item.manager)+" ")])}},{key:"phone",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.phone'),"sortable":true}},[_vm._v(" "+_vm._s(item.phone)+" ")])}},{key:"mail",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.email'),"sortable":true}},[_vm._v(" "+_vm._s(item.mail)+" ")])}},{key:"company",fn:function(ref){
var company = ref.item.company;
return _c('div',{attrs:{"title":_vm.$t('common.company'),"sortable":true,"sortBy":"company.name"}},[(company)?_c('restricted-link',{attrs:{"entity":"company","to":("/sst/company/" + (company.id))}},[_vm._v(" "+_vm._s(company.name)+" ")]):_vm._e()],1)}},{key:"actions",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('router-link',{staticClass:"button is-primary",attrs:{"to":'/sst/agency/' + item.id}},[_c('i',{staticClass:"fa fa-search"})])],1)}}])})],1),_c('modal',{ref:"createAgencyModal",attrs:{"title":_vm.$t('agencies.agencyCreation')},on:{"close":_vm.closeCreateAgency,"create":_vm.createAgency}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('siret-field',{attrs:{"label":_vm.$t('common.siret'),"inline":false,"edit":true},model:{value:(_vm.siret),callback:function ($$v) {_vm.siret=$$v},expression:"siret"}}),_c('many2one-field',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"label":_vm.$t('common.company'),"fetch":_vm.$api.fetchCompanies,"reference":"name","entityLocation":("/sst/company/" + (_vm.agency.company ? _vm.agency.company.id : '')),"entity":"company","columns":_vm.columns,"inline":false,"edit":true,"required":"","hasFilter":true,"name":"company2one","error":_vm.errors.has('company2one')},model:{value:(_vm.agency.company),callback:function ($$v) {_vm.$set(_vm.agency, "company", $$v)},expression:"agency.company"}}),_c('text-field',{attrs:{"label":_vm.$t('common.agency'),"inline":false,"edit":!_vm.siret || _vm.siret.inThirdParty === false,"required":""},model:{value:(_vm.agency.edifice),callback:function ($$v) {_vm.$set(_vm.agency, "edifice", $$v)},expression:"agency.edifice"}}),_c('textarea-field',{attrs:{"label":_vm.$t('common.address'),"inline":false,"edit":!_vm.siret || _vm.siret.inThirdParty === false},model:{value:(_vm.agency.address),callback:function ($$v) {_vm.$set(_vm.agency, "address", $$v)},expression:"agency.address"}}),(_vm.siret && _vm.siret.inThirdParty !== false)?_c('checkbox-field',{attrs:{"label":"Suivi e-attestation","inline":false,"edit":true},model:{value:(_vm.agency.synchronize),callback:function ($$v) {_vm.$set(_vm.agency, "synchronize", $$v)},expression:"agency.synchronize"}}):_vm._e(),(
            _vm.siret &&
            _vm.siret.inThirdParty !== false &&
            _vm.agency.synchronize &&
            !_vm.siret.onboarded
          )?_c('text-field',{attrs:{"type":"email","label":_vm.$t('agencies.columns.inviteMail'),"inline":false,"edit":true},model:{value:(_vm.agency.inviteMail),callback:function ($$v) {_vm.$set(_vm.agency, "inviteMail", $$v)},expression:"agency.inviteMail"}}):_vm._e(),(
            _vm.siret &&
            _vm.siret.inThirdParty !== null &&
            _vm.agency.synchronize &&
            !_vm.siret.onboarded
          )?_c('article',{staticClass:"message is-info",attrs:{"slot":"helper"},slot:"helper"},[_c('div',{staticClass:"message-body"},[_vm._v(_vm._s(_vm.$t("agencies.inviteMailHint")))])]):_vm._e()],1),_c('div',{staticClass:"column is-half"},[_c('text-field',{attrs:{"label":_vm.$t('common.manager'),"inline":false,"edit":true},model:{value:(_vm.agency.manager),callback:function ($$v) {_vm.$set(_vm.agency, "manager", $$v)},expression:"agency.manager"}}),_c('text-field',{attrs:{"label":_vm.$t('common.phone'),"inline":false,"edit":true},model:{value:(_vm.agency.phone),callback:function ($$v) {_vm.$set(_vm.agency, "phone", $$v)},expression:"agency.phone"}}),_c('text-field',{attrs:{"label":_vm.$t('common.email'),"type":"email","inline":false,"edit":true},model:{value:(_vm.agency.mail),callback:function ($$v) {_vm.$set(_vm.agency, "mail", $$v)},expression:"agency.mail"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }