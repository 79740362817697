export const EntitiesName = Object.freeze({
  Agency: 'agency',
  Attachement: 'attachement',
  BpuSst: 'bpu_sst',
  BpuTemplate: 'bpu_template',
  Category: 'category',
  Company: 'company',
  ContractTemplate: 'contract_template',
  Dc4: 'budget_dc4',
  Deposit: 'deposit',
  DocumentType: 'document_type',
  Dsp: 'dsp',
  Imputation: 'imputation',
  MarketSst: 'market_sst',
  MonthlyStatement: 'monthly_statement',
  Notification: 'notification',
  Nro: 'nro',
  Od: 'budget_od',
  OscarProject: 'oscar_project',
  OscarSubProject: 'oscar_subproject',
  OsTemplate: 'os_template',
  Order: 'order',
  PaymentGuarantee: 'payment-guarantee',
  Pm: 'pm',
  Prestation: 'prestation',
  PrestationType: 'prestation_type',
  ProjectType: 'project_type',
  Project: 'project',
  PmProject: 'pm_project',
  Quantitative: 'budget_quantitative',
  Warranties: 'budget_rg_caution',
  Role: 'role',
  SubcontractorMarket: 'subcontractor_market',
  Unit: 'unit',
  User: 'user',
  UserProject: 'user_project',
  UserAgency: 'user_agency',
  Contract: 'contract',
  Popup: 'popup'
});

export const Entities = [];
