<template>
  <div class="home">
    <div class="container is-fluid" v-if="user.id">
      <page-header
        :entity="$entitiesName.User"
        :edit="edit"
        :save="save"
        :cancel="cancel"
        :remove="user && user.active ? remove : null"
      >
        <h1 class="title">
          <breadcrumb
            location="/users"
            :text="$t('entities.user')"
          ></breadcrumb>
          <span data-test="sel-username">{{ user.name }}</span>
        </h1>
        <template slot="actions">
          <button
            v-if="getRights($entitiesName.User).update"
            class="button is-primary"
            @click="$refs.changePerimeterModal.open()"
          >
            {{ $t("users.changePerimeter") }}
          </button>
        </template>
      </page-header>

      <div class="columns">
        <div class="column">
          <div class="panel infos">
            <div class="panel-heading has-background-primary">
              {{ $t("common.information") }}
            </div>
            <div class="panel-block is-block no-border">
              <text-field
                :label="$t('common.name')"
                v-model="user.name"
                required
              ></text-field>
              <text-field
                :label="$t('common.email')"
                v-model="user.mail"
                type="email"
                required
              ></text-field>
              <text-field
                :label="$t('common.phone')"
                v-model="user.phone"
              ></text-field>
              <many2one-field
                :label="$t('users.columns.manager')"
                v-model="user.manager"
                :fetch="$api.fetchUsers"
                reference="name"
                :entityLocation="`/user/${
                  user.manager ? user.manager.id : ''
                } `"
                :columns="columns"
                :edit="editMode"
              ></many2one-field>
              <selector-field
                :label="$t('common.profile')"
                v-model="user.role"
                :options="$api.fetchRolesAsList"
                required
                data-test="sel-user-role"
              >
                <template slot-scope="{ option }">{{ option.name }}</template>
              </selector-field>
              <text-field
                :label="$t('users.authorizationAmount')"
                v-model="user.engagement"
                required
              ></text-field>
              <text-field
                :label="$t('common.service')"
                v-model="user.service"
                required
              ></text-field>
              <text-field
                :label="$t('common.quality')"
                v-model="user.quality"
                required
              ></text-field>
              <checkbox-field
                cy-data="user-active"
                :label="$t('common.active')"
                v-model="user.active"
              />
              <field
                v-if="user.parent"
                :label="$t('users.mainProfile')"
                readonly
              >
                <router-link :to="'/user/' + user.parent.id"
                  >{{ user.parent.name }} -
                  {{ user.parent.role.name }}</router-link
                >
              </field>

              <div v-if="message && !editMode" class="message-info">
                <div class="notification is-warning is-light">
                  {{ message }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="panel" v-if="!user.parent">
            <panel-header
              :title="$t('users.secondaryProfiles')"
              @add="addProfile"
              :fetch="$api.fetchRoles"
              :columns="{ name: $t('entities.role') }"
            >
              <button
                v-if="getRights($entitiesName.UserAgency).create"
                slot="activator"
                cy-data="button-addProfile"
                class="button is-small is-success"
              >
                <i class="fa fa-plus"></i>
              </button>
            </panel-header>
            <div class="panel-block">
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <td>{{ $t("common.name") }}</td>
                    <td>{{ $t("common.role") }}</td>
                    <td>{{ $t("common.active") }}</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(c, index) in user.children"
                    :key="'children_' + index"
                  >
                    <td>{{ c.name }}</td>
                    <td>{{ c.role.name }}</td>
                    <td>
                      <checkbox-field v-model="c.active" />
                    </td>
                    <td>
                      <div class="is-pulled-right">
                        <button
                          v-if="getRights($entitiesName.User).read"
                          class="button is-small is-primary"
                          @click="$router.push(`/user/${c.id}`)"
                        >
                          <i class="fa fa-search"></i>
                        </button>
                        <button
                          v-if="getRights($entitiesName.UserAgency).delete"
                          class="button is-small is-danger"
                          @click="removeProfile(c)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="panel">
            <panel-header
              :title="$t('common.agencies')"
              @add="addAgency"
              :fetch="$api.fetchAgencies"
              :columns="columnsAgency"
            >
              <button
                v-if="getRights($entitiesName.UserAgency).create"
                slot="activator"
                cy-data="button-addAgency"
                class="button is-small is-success"
              >
                <i class="fa fa-plus"></i>
              </button>
            </panel-header>
            <div class="panel-block">
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <td>{{ $t("common.company") }}</td>
                    <td>{{ $t("agencies.columns.agencyCode") }}</td>
                    <td>{{ $t("common.siret") }}</td>
                    <td>{{ $t("agencies.columns.manager") }}</td>
                    <td>{{ $t("common.contactInformation") }}</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(a, index) in agencies" :key="index">
                    <td>{{ a.agency.company.name }}</td>
                    <td>{{ a.agency.edifice }}</td>
                    <td>{{ a.agency.siret }}</td>
                    <td>{{ a.agency.manager }}</td>
                    <td>{{ a.agency.address }}</td>
                    <td>
                      <button
                        v-if="getRights($entitiesName.UserAgency).delete"
                        class="button is-small is-danger"
                        :cy-data="`cy-${a.agency.edifice
                          .replaceAll(/[ ']/g, '')
                          .toLowerCase()}-delete`"
                        @click="removeAgency(a.agency)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="panel">
        <panel-header
          :title="$t('common.projects')"
          @add="addProjects"
          :fetch="$api.fetchProjectsFilterByUser.bind(this, id)"
          :columns="columnsProject"
          multiSelect
        >
          <button
            v-if="getRights($entitiesName.UserProject).create"
            slot="activator"
            data-test="sel-user-add-project"
            cy-data="button-addProject"
            class="button is-small is-success"
          >
            <i class="fa fa-plus"></i>
          </button>
        </panel-header>
        <div class="panel-block">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <td>{{ $t("common.name") }}</td>
                <td>{{ $t("common.code") }}</td>
                <td>{{ $t("users.projectType") }}</td>
                <td>{{ $t("projects.columns.manager") }}</td>
                <td>{{ $t("common.contactInformation") }}</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(p, index) in projects" :key="index">
                <td>{{ p.project.name }}</td>
                <td>{{ p.project.code }}</td>
                <td>{{ p.project.type.name }}</td>
                <td>{{ p.project.manager }}</td>
                <td>{{ p.project.address }}</td>
                <td>
                  <div class="is-pulled-right">
                    <button
                      v-if="getRights($entitiesName.Project).read"
                      class="button is-small is-primary"
                      @click="editProject(p)"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                    <button
                      v-if="getRights($entitiesName.UserProject).delete"
                      class="button is-small is-danger"
                      @click="removeProject(p.project)"
                      :cy-data="`cy-${p.project.name
                        .replaceAll(/[ ']/g, '')
                        .toLowerCase()}-delete`"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="panel">
            <div class="panel-heading has-background-primary">
              {{ $t('account.delegation.userDelegations') }}
              <button
                slot="activator"
                cy-data="button-add-delegation"
                class="button is-small is-success"
                @click="openDelegationModal"
                v-if="userRoleOptions.includes($roleOptions.USERS.CAN_ADMIN_DELEGATE)"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
            <div class="panel-block">
              <div class="has-text-centered is-full-width" v-if="userCreatedDelegations.length <= 0">
                {{ $t('account.delegation.userNoDelegation') }}
              </div>
              <table class="table is-fullwidth" v-else>
                <thead>
                <tr>
                  <td>{{ $t("account.delegation.delegateTo") }}</td>
                  <td>{{ $t('expressions.startDate') }}</td>
                  <td>{{ $t('expressions.endDate') }}</td>
                  <td></td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(d, index) in userCreatedDelegations" :key="index">
                  <td>{{ d.delegateTo.name }}</td>
                  <td>{{ d.startDate | date }}</td>
                  <td>{{ d.endDate | date }}</td>
                  <td>
                    <button
                      class="button is-small is-danger"
                      @click="deleteDelegation(d)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="panel">
            <div class="panel-heading has-background-primary">
              {{ $t('account.delegation.receivedDelegations') }}
            </div>
            <div class="panel-block">
              <div class="has-text-centered is-full-width" v-if="userReceivedDelegations.length <= 0">
                {{ $t('account.delegation.userNoReceivedDelegation') }}
              </div>
              <table class="table is-fullwidth" v-else>
                <thead>
                <tr>
                  <td>{{ $t("account.delegation.delegateFrom") }}</td>
                  <td>{{ $t('expressions.startDate') }}</td>
                  <td>{{ $t('expressions.endDate') }}</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(d, index) in userReceivedDelegations" :key="index">
                  <td>{{ d.delegateFrom.name }}</td>
                  <td>{{ d.startDate | date }}</td>
                  <td>{{ d.endDate | date }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <modal
        ref="changePerimeterModal"
        :title="$t('users.assignPerimeterToUser')"
        :action="$t('common.validate')"
        @create="changePerimeter"
      >
        <datatable
          singleSelect
          :fetch="$api.fetchUsers"
          v-model="newUser"
          filterColumns
          :columns="{ name: $t('common.name'), mail: $t('common.email') }"
          :size="15"
        ></datatable>
      </modal>
      <modal
        ref="addAdminDelegationModal"
        :title="$t('account.delegation.delegationCreation')"
        @create="createDelegation"
        @close="closeModal"
      >
        <div class="columns">
          <div class="column">
            <many2one-field
              :label="$t('entities.user')"
              v-model="delegation.delegateTo"
              :fetch="fetchAdminDelegationUsers"
              reference="name"
              :columns="delegationUserColumns"
              :inline="false"
              :edit="true"
              required
              :hasFilter="true"
              v-validate="{ required: true }"
              name="users2one"
              :error="errors.has('markets2one')"
              cy-data="delegation-user"
            ></many2one-field>
            <date-field
              :label="$t('expressions.startDate')"
              v-model="delegation.startDate"
              :inline="false"
              :edit="true"
              cy-data="delegation-start-date"
              required
            ></date-field>
            <date-field
              :label="$t('expressions.endDate')"
              v-model="delegation.endDate"
              :inline="false"
              :edit="true"
              cy-data="delegation-end-date"
              @focusout="updateDelegationEndDate"
              required
            ></date-field>
          </div>
        </div>
      </modal>
    </div>
    <div v-else class="container is-fluid">
      <page-header :entity="$entitiesName.User">
        <h1 class="title">
          <breadcrumb
            location="/users"
            :text="$t('entities.user')"
          ></breadcrumb>
        </h1>
      </page-header>

      <div class="panel infos" v-if="!loading">
        <div class="panel-heading has-background-primary">
          {{ $t("common.information") }}
        </div>
        <div class="panel-block is-block no-border">
          Cet utilisateur n'existe pas ou ne peut pas être affiché.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  props: ["id"],
  name: "users",
  data() {
    return {
      loading: false,
      user: {},
      delegation: {},
      userCreatedDelegations: [],
      userReceivedDelegations: [],
      newUser: {},
      agencies: [],
      projects: [],
      companies: [],
      delegationUserColumns: {
        name: this.$t("common.name"),
        mail: "E-Mail",
        "role.name": this.$t("entities.role"),
        service: this.$t("common.service"),
      },
      columns: {
        name: {
          title: this.$t("common.name"),
          filter: "",
        },
        mail: {
          title: this.$t("common.email"),
          filter: "",
        },
        "role.name": this.$t("entities.role"),
        service: {
          title: this.$t("common.service"),
          filter: "",
        },
      },
      columnsAgency: {
        "company.name": {
          title: this.$t("common.company"),
          filter: "",
        },
        edifice: {
          title: this.$t("common.edifice"),
          filter: "",
        },
        siret: {
          title: this.$t("common.siret"),
          filter: "",
        },
        manager: {
          title: this.$t("agencies.columns.manager"),
          filter: "",
        },
      },
      columnsProject: {
        name: {
          title: this.$t("common.name"),
          filter: "",
        },
        code: {
          title: this.$t("common.code"),
          filter: "",
        },
        "type.name": {
          title: this.$t("common.type"),
          filter: "",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      editMode: "states/isEdition",
      getRights: "auth/getRights",
      userRoleOptions: "auth/getOptions"
    }),
    message() {
      let message = null;
      if (!this.user.role) {
        return message;
      }

      if (this.user.role.visibility === 2 && this.projects.length === 0) {
        message =
          "L'utilisateur n'a pas de projet associé, il ne pourra pas se connecter à l'application";
      }
      if (this.user.role.visibility === 3 && !this.user.manager) {
        message =
          "L'utilisateur n'a pas de manager, il ne pourra pas se connecter à l'application";
      }
      if (
        this.user.role.visibility === 4 &&
        (this.projects.length === 0 || this.agencies.length === 0)
      ) {
        message =
          "L'utilisateur n'a pas de projet et/ou d'etablissement associé, il ne pourra pas se connecter à l'application";
      }
      return message;
    },
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(`/user/${this.id}`)
        .then((response) => {
          this.user = response.data;
          this.$store.dispatch("states/setEdition", false);
        })
        .finally(() => {
          this.loading = false;
        });

      axios
        .get(`/delegation/all/${this.id}`)
        .then((response) => {
          this.userCreatedDelegations = response.data;
        }).then(() => {
        axios
          .get(`/delegation/user-delegations/${this.id}`)
          .then((response) => {
            this.userReceivedDelegations = response.data;
            this.$store.dispatch("states/setEdition", false);
          })
      })

      axios.get(`/user/${this.id}/agencies`).then((response) => {
        this.agencies = response.data;
      });

      axios.get(`/user/${this.id}/projects`).then((response) => {
        this.projects = response.data;
      });
    },
    save() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          return axios.put(`/user/${this.id}`, this.user).then((response) => {
            if (response.data.success) {
              this.$store.dispatch("states/setEdition", false);
            }
          });
        }
        return Promise.resolve();
      });
    },
    edit() {
      this.$store.dispatch("states/setEdition", true);
    },
    cancel() {
      this.fetchData();
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir désactiver cet utilisateur ?",
        () =>
          axios
            .delete(`/user/${this.id}/delete`, this.user)
            .then((response) => {
              if (response.data.success) {
                this.$router.replace("/users");
              }
            })
      );
    },
    /* Agencies */
    addAgency(agency) {
      axios.post(`/user/${this.user.id}/agency/${agency.id}`).then(() => {
        this.fetchData();
      });
    },
    removeAgency(agency) {
      this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () => {
          axios.delete(`/user/${this.user.id}/agency/${agency.id}`).then(() => {
            this.fetchData();
          });
        }
      );
    },
    /* Projects */
    addProjects(projects) {
      var apiCalls = [];
      projects.forEach((project) =>
        apiCalls.push(axios.post(`/project/${project.id}/user/${this.user.id}`))
      );

      Promise.all(apiCalls).then(() => this.fetchData());
    },
    removeProject(project) {
      this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () => {
          axios
            .delete(`/project/${project.id}/user/${this.user.id}`)
            .then(() => {
              this.fetchData();
            });
        }
      );
    },
    editProject(p) {
      this.$router.push(`/project/${p.project.id}`);
    },
    /* Profiles */
    addProfile(role) {
      axios
        .post(`/user/${this.user.id}/profile/${role.id}`)
        .then((response) => {
          this.$router.push({
            name: "User",
            params: { id: response.data.id },
          });
        });
    },
    removeProfile(profile) {
      this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () => {
          axios.delete(`/user/${profile.id}/delete`).then(() => {
            this.fetchData();
          });
        }
      );
    },
    changePerimeter() {
      axios
        .post(`/user/${this.user.id}/perimeter/${this.newUser.id}`)
        .then(() => {
          this.$awn.success("Le périmètre a bien été changé.");
        })
        .finally(() => {
          this.$refs.changePerimeterModal.close();
          this.newUser = {};
        });
    },
    openDelegationModal() {
      this.$refs.addAdminDelegationModal.open();
    },
    createDelegation()
    {
      this.$validator.validateAll().then((result) => {
        if(!result)
          return;
        this.delegation.delegateFrom = this.user;
        axios.post("/delegation/create", this.delegation).then((response) => {
          if (!response.data.success) {
            return;
          }
          this.delegation = {}
          this.$refs.addAdminDelegationModal.close();
          this.fetchData();
        });
      })
    },
    closeModal()
    {
      this.delegation = {};
    },
    fetchAdminDelegationUsers(p)
    {
      return this.$api.fetchAdminDelegationUsers(p, this.id);
    },
    updateDelegationEndDate(val)
    {
      if(moment(val).isBefore(this.delegation.startDate))
        this.$nextTick(() => this.delegation.endDate = this.delegation.startDate);
    },
    deleteDelegation(delegation)
    {
      this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () => {
          axios.delete(`/delegation/${delegation.id}`).then(() => {
            this.fetchData();
          });
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-block {
  position: relative;
}
.message-info {
  position: absolute;
  top: 40%;
  width: 40%;
  right: 20px;
}
</style>
