var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',[_vm._v(_vm._s(_vm.rowNumber))]),_c('td',[_c('text-field',{attrs:{"inline":false,"edit":true},model:{value:(_vm.value.reference),callback:function ($$v) {_vm.$set(_vm.value, "reference", $$v)},expression:"value.reference"}})],1),_c('td',[_c('selector-field-mod',{ref:"categoryRef",style:({ maxWidth: '200px' }),attrs:{"edit":true,"inline":false,"options":_vm.$api.fetchAllParentCategories,"showEmpty":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.name))]}}]),model:{value:(_vm.parentCategory),callback:function ($$v) {_vm.parentCategory=$$v},expression:"parentCategory"}})],1),_c('td',[_c('selector-field-mod',{ref:"subCategoryRef",style:({ maxWidth: '200px' }),attrs:{"edit":true,"inline":false,"options":_vm.fetchSubCategories},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.name))]}}]),model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}})],1),_c('td',[_c('text-field',{attrs:{"inline":false,"edit":true},model:{value:(_vm.prestation.code),callback:function ($$v) {_vm.$set(_vm.prestation, "code", $$v)},expression:"prestation.code"}})],1),_c('td',[_c('text-field',{attrs:{"inline":false,"edit":true},model:{value:(_vm.prestation.description),callback:function ($$v) {_vm.$set(_vm.prestation, "description", $$v)},expression:"prestation.description"}})],1),_c('td',[_c('selector-field',{ref:"unitRef",style:({ maxWidth: '200px' }),attrs:{"edit":true,"inline":false,"options":_vm.fetchUnits,"pkey":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.name))]}}]),model:{value:(_vm.prestation.unit),callback:function ($$v) {_vm.$set(_vm.prestation, "unit", $$v)},expression:"prestation.unit"}})],1),_c('td',[_c('selector-field',{ref:"typeRef",style:({ maxWidth: '200px'  }),attrs:{"edit":true,"inline":false,"options":_vm.fetchTypes,"pkey":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.name))]}}]),model:{value:(_vm.prestation.type),callback:function ($$v) {_vm.$set(_vm.prestation, "type", $$v)},expression:"prestation.type"}})],1),(_vm.newMode)?_c('td',[_c('button',{staticClass:"button has-background-success is-pulled-right",on:{"click":_vm.save}},[_c('i',{staticClass:"fa fa-plus white"})])]):_c('td',[_c('button',{staticClass:"button has-background-success",on:{"click":_vm.closeEdit}},[_c('i',{staticClass:"fa fa-plus white"})])])])}
var staticRenderFns = []

export { render, staticRenderFns }