<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$entitiesName.Prestation"
        :edit="edit"
        :save="save"
        :cancel="cancel"
        :remove="remove"
      >
        <h1 class="title">
          <breadcrumb
            location="/prestations"
            :text="$t('entities.prestation')"
          ></breadcrumb>
          {{ prestation.reference }}
        </h1>
      </page-header>

      <div class="panel infos">
        <div class="panel-heading has-background-primary">
          {{ $t("common.information") }}
        </div>
        <div class="panel-block is-block no-border">
          <many2one-field
            :label="$t('entities.bpuTemplate')"
            v-model="prestation.bpu"
            :fetch="$api.fetchTemplates"
            reference="name"
            :columns="{ name: 'Nom' }"
            required
            :readonly="true"
            :hasFilter="true"
            v-validate="{ required: true }"
            :entityLocation="`/sst/bputemplate/${
              prestation.bpu ? prestation.bpu.id : ''
            }`"
          ></many2one-field>
          <text-field
            :label="$t('common.reference')"
            v-model="prestation.reference"
            required
          />
          <text-field
            :label="$t('prestations.columns.itemCode')"
            v-model="prestation.code"
            required
          />
          <textarea-field
            :label="$t('common.designation')"
            v-model="prestation.description"
            required
          />
          <many2one-field
            :label="$t('common.unit')"
            v-model="prestation.unit"
            :fetch="$api.fetchUnits"
            reference="name"
            :columns="{ name: 'Nom' }"
            required
            :hasFilter="true"
            v-validate="{ required: true }"
          ></many2one-field>
          <many2one-field
            :label="$t('common.type')"
            v-model="prestation.type"
            :fetch="$api.fetchTypes"
            reference="name"
            :columns="{ name: 'Nom' }"
            required
            :hasFilter="true"
            v-validate="{ required: true }"
          ></many2one-field>
          <many2one-field
            :label="$t('common.category')"
            v-model="category"
            :fetch="$api.fetchParentCategories"
            reference="name"
            :columns="{ name: 'Nom' }"
            required
            :hasFilter="true"
            v-validate="{ required: true }"
          ></many2one-field>
          <many2one-field
            :label="$t('common.subcategory')"
            v-model="subCategory"
            :fetch="$api.fetchSubCategoriesPage.bind(this, category.id)"
            reference="name"
            :columns="{ name: 'Nom' }"
            :hasFilter="true"
            required
            v-validate="{ required: true }"
          ></many2one-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "prestation",
  props: ["id"],
  data() {
    return {
      prestation: {},
    };
  },
  computed: {
    ...mapGetters({
      editMode: "states/isEdition",
    }),
    category: {
      get() {
        if (!this.prestation.category) {
          return "";
        }
        return this.prestation.category.parent
          ? this.prestation.category.parent
          : this.prestation.category;
      },
      set(val) {
        if (this.prestation.category) {
          this.prestation.category = val;
        }
      },
    },
    subCategory: {
      get() {
        if (!this.prestation.category) {
          return null;
        }
        return this.prestation.category.parent
          ? this.prestation.category
          : null;
      },
      set(val) {
        if (this.prestation.category) {
          this.prestation.category = val;
          this.prestation.category.parent = this.category;
        }
      },
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get(`/prestation/${this.id}`).then((response) => {
        this.prestation = response.data;
        this.$store.dispatch("states/setEdition", false);
      });
    },
    edit() {
      this.$store.dispatch("states/setEdition", true);
    },
    cancel() {
      this.fetchData();
    },
    save() {
      return axios
        .put(`/prestation/${this.id}`, this.prestation)
        .then((response) => {
          if (response.data.success) {
            this.fetchData();
          }
        });
    },
    remove() {
      const self = this;
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios.delete(`/prestation/${self.id}`).then((response) => {
            if (response.data.success) {
              self.$router.replace("/prestations");
            }
          })
      );
    },
  },
};
</script>

<style scoped>
</style>
