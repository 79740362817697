var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"container is-fluid"},[_c('page-header',{attrs:{"entity":_vm.$entitiesName.User,"create":_vm.openCreateModal}},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("entities.users")))])]),_c('datatable',{ref:"userList",attrs:{"fetch":_vm.$api.fetchUsers,"enableDownload":true,"objectName":"users"},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.name'),"sortable":true}},[_vm._v(" "+_vm._s(item.name)+" ")])}},{key:"mail",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.email'),"sortable":true}},[_vm._v(" "+_vm._s(item.mail)+" ")])}},{key:"role",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.profile'),"sortable":true}},[(item.role)?_c('span',[_vm._v(_vm._s(item.role.name))]):_vm._e()])}},{key:"manager.name",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('users.columns.manager'),"sortable":true}},[(item.manager)?_c('restricted-link',{attrs:{"entity":_vm.$entitiesName.User,"to":("/user/" + (item.manager.id))}},[_vm._v(" "+_vm._s(item.manager.name)+" ("+_vm._s(item.manager.quality)+") ")]):_vm._e()],1)}},{key:"service",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.service')}},[_vm._v(" "+_vm._s(item.service)+" ")])}},{key:"quality",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.quality')}},[_vm._v(" "+_vm._s(item.quality)+" ")])}},{key:"active",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.active'),"nofilter":true}},[_c('checkbox-field',{attrs:{"edit":false,"inline":true},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1)}},{key:"actions",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('router-link',{staticClass:"button is-primary",attrs:{"to":'/user/' + item.id}},[_c('i',{staticClass:"fa fa-search"})])],1)}}])})],1),_c('modal',{ref:"createUserModal",attrs:{"title":_vm.$t('users.addUser')},on:{"create":_vm.createUser}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('text-field',{attrs:{"label":_vm.$t('users.ldapCommonName'),"inline":false,"edit":true,"data-test":"sel-ldap-cn","required":""},model:{value:(_vm.user.cn),callback:function ($$v) {_vm.$set(_vm.user, "cn", $$v)},expression:"user.cn"}}),_c('text-field',{attrs:{"label":_vm.$t('common.phone'),"data-test":"sel-phone","inline":false,"edit":true},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('selector-field',{attrs:{"label":_vm.$t('common.profile'),"options":_vm.$api.fetchRolesAsList,"inline":false,"edit":true,"preselect":true,"data-test":"sel-user-role","required":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.name))]}}]),model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}}),(_vm.user.role)?_c('article',{staticClass:"message",class:{
            'is-danger': _vm.user.role.visibility == 1,
            'is-warning':
              _vm.user.role.visibility > 1 && _vm.user.role.visibility < 4,
            'is-info': _vm.user.role.visibility == 4,
          },attrs:{"slot":"helper"},slot:"helper"},[_c('div',{staticClass:"message-body"},[_vm._v(_vm._s(_vm.user.role.description))])]):_vm._e(),_c('many2one-field',{attrs:{"label":_vm.$t('users.columns.manager'),"fetch":_vm.$api.fetchUsers,"reference":"name","entityLocation":("/user/" + (_vm.user.manager ? _vm.user.manager.id : '')),"columns":_vm.columns,"inline":false,"edit":true,"data-test":"sel-user-manager"},model:{value:(_vm.user.manager),callback:function ($$v) {_vm.$set(_vm.user, "manager", $$v)},expression:"user.manager"}}),_c('text-field',{attrs:{"label":_vm.$t('users.authorizationAmount'),"type":"number","inline":false,"edit":true,"data-test":"sel-user-engagement","required":""},model:{value:(_vm.user.engagement),callback:function ($$v) {_vm.$set(_vm.user, "engagement", $$v)},expression:"user.engagement"}}),_c('text-field',{attrs:{"label":_vm.$t('common.service'),"data-test":"sel-user-service","inline":false,"edit":true,"required":""},model:{value:(_vm.user.service),callback:function ($$v) {_vm.$set(_vm.user, "service", $$v)},expression:"user.service"}}),_c('text-field',{attrs:{"label":_vm.$t('common.quality'),"data-test":"sel-user-quality","inline":false,"edit":true,"required":""},model:{value:(_vm.user.quality),callback:function ($$v) {_vm.$set(_vm.user, "quality", $$v)},expression:"user.quality"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }